function getBrandIdFromBrandName(brand) {
  const brands = new Map([
    ['ibis', 'IBP'],
    ['novotel', 'NOV'],
    ['mantra', 'MTA'],
    ['mgallery', 'MGA'],
    ['mercure', 'MER'],
    ['sofitel', 'SOF']
  ]);

  // returns novotel by default for historical reasons (component was implemented for novotel only at first)
  return brands.get(brand?.toLowerCase()) || brands.get('novotel');
}

export function formatNewsletterUrl(
  { lang, brand } = { lang: 'en', brand: 'novotel' }
) {
  const langUrl = lang || 'en';
  const brandUrl = getBrandIdFromBrandName(brand) || 'NOV';
  return `https://secure.accor.com/newsletter/index.${langUrl}.shtml#/${brandUrl}/subscribe/email?email=`;
}


function getDataLayer() {
  const dataLayerElement = document.querySelector('[data-cmp-datalayer]');
  const dataLayer = dataLayerElement?.getAttribute('data-cmp-datalayer');

  return dataLayer ? JSON.parse(dataLayer) : null;
}

export function getBrandFromDataLayer() {
  return getDataLayer()?.brandContext;
}

export function validateEmail(email) {
  const re = /^\S+@\S+\.\S+$/;
  return re.test(email);
}
