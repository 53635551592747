export { logout } from './logout.js'
export { setToken } from './setToken.js'
export * from './getAuthUrl.js'
export { getToken } from './getToken.js'
export { refreshToken } from './refreshToken.js'
export { getRegisterFormUrl } from './getRegisterFormUrl.js'
export { hasRequiredTokenACR } from './hasRequiredTokenACR.js'
export {
  getRegisterWithContextUrl,
  getNewRegisterUrl,
} from './getRegisterWithContextUrl.js'
export { Error }

import { Error as E_logout } from './logout.js'
import { Error as E_refreshToken } from './refreshToken.js'
import { Error as E_setToken } from './setToken.js'
import { Error as E_autoLogin } from './getToken.js'

import { combineObjects } from '../common/utils.js'

const Error = combineObjects(E_logout, E_setToken, E_autoLogin, E_refreshToken)
