export {
  attachDefinedQueryParamsToString,
  attachQueryParamsToString,
  combineObjects,
  debouncePromise,
  debouncePromiseGlobally,
  Enum,
  Environment,
  generateTraceId,
  getEnv,
  getLang,
  getUrlParams,
  getUrlSearchParams,
  makeSearchParamsFromObject,
  once,
  removeNullAndUndefinedEntries,
  extractAcrFromToken,
}

import Cookies from 'js-cookie'

const isObjectEmpty = obj => Object.keys(obj || {}).length === 0

const getLang = (global = window) => {
  const gbToEn = code => (code === 'gb' ? 'en' : code)
  const lang = global.document.documentElement.lang
  return gbToEn(lang) || 'en'
}

const attachQueryParamsToString = (
  string,
  params,
  paramsMaker = makeSearchParamsFromObject
) => string + (isObjectEmpty(params) ? '' : '?' + paramsMaker(params))

const attachDefinedQueryParamsToString = (string, params) =>
  attachQueryParamsToString(string, removeNullAndUndefinedEntries(params))

const makeAppendableFromObject = Appendable => obj =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    acc.append(key, value)
    return acc
  }, new Appendable())

const makeSearchParamsFromObject = makeAppendableFromObject(URLSearchParams)

const Enum = (...enumerations) =>
  enumerations.reduce((acc, cur) => {
    acc[cur] = cur
    return acc
  }, {})

const combineObjects = (...xs) => xs.reduce((acc, x) => ({ ...acc, ...x }))

const debouncePromise = fn => {
  let promise = null

  return (...args) => (promise ||= fn(...args).finally(() => (promise = null)))
}

const debouncePromiseGlobally =
  (fn, variableName, global = window) =>
  (...args) =>
    (global[variableName] ||= fn(...args).finally(
      () => (global[variableName] = null)
    ))

const generateTraceId = () =>
  '##-#-#-#-###'.replace(/#/g, generateRandomHexWord)

const generateRandomHexWord = () =>
  Math.floor(Math.random() * 0x10000)
    .toString(16)
    .padStart(4, '0')

const once = fn => {
  let hasBeenCalled = false
  let result
  return (...args) => {
    if (hasBeenCalled) return result
    hasBeenCalled = true
    result = fn(...args)
    return result
  }
}

const convertFirstArgToURL =
  fn =>
  (firstArg, ...rest) =>
    fn(new URL(firstArg), ...rest)

const getUrlHashParams = convertFirstArgToURL(
  url => new URLSearchParams(url.hash.slice(1))
)

const getUrlSearchParams = convertFirstArgToURL(url => url.searchParams)

const getUrlParams = convertFirstArgToURL(url => {
  const getters = [getUrlHashParams, getUrlSearchParams]
  const callGetterAndGetEntries = getter => [...getter(url).entries()]
  const combinedEntries = getters.flatMap(callGetterAndGetEntries)
  return Object.fromEntries(combinedEntries)
})

const filterObject =
  (valuesPredicate, keysPredicate = () => true) =>
  object =>
    Object.entries(object).reduce((acc, [key, value]) => {
      if (valuesPredicate(value) && keysPredicate(key)) acc[key] = value
      return acc
    }, {})

const removeNullAndUndefinedEntries = filterObject(
  value => value !== null && value !== undefined
)

const Environment = Enum('Production', 'Staging')
const getEnv = (cookie = Cookies.get('_Hw2h_')) => [
  (cookie || '').match(/\.(p|s)\d{2}[a-d]/)
    ? Environment.Production
    : Environment.Staging,
  cookie?.slice(1, -1),
]

const base64UrlDecode = str => {
  // Replace URL-safe characters with regular Base64 characters
  let base64 = str.replace(/-/g, '+').replace(/_/g, '/')

  // Pad the string with '=' until its length is a multiple of 4
  while (base64.length % 4) {
    base64 += '='
  }

  // Decode the Base64 string and convert it to a URI component
  return decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}

const extractAcrFromToken = token => {
  if (!token) return false
  const [, payload] = token.split('.')
  const decodedPayload = base64UrlDecode(payload)
  const payloadObj = JSON.parse(decodedPayload)

  return payloadObj.acr
}
