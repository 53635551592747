export default class HeaderMenuProduct extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'header-menu-product';


  constructor(componentHost) {
    super(componentHost);
  }

  initialize() {
    if (!this.componentHost.dataset.initialized) {
      this.checkAndSetImmersiveClass();
      this.componentHost.dataset.initialized = 'true';
    }
    this.navigationPanel = this.componentHost.querySelectorAll('.link-navigation__panel');
    this.navigationList = this.componentHost.querySelector('.link-navigation_nav_menu-list');
    this.header = this.componentHost.closest('header');


    this.navigationList.addEventListener(CoreJS.DomEventConstants.MOUSE_ENTER, () => (
      this.hoverEnter()
    ));
    this.navigationList.addEventListener(CoreJS.DomEventConstants.MOUSE_LEAVE, () => {
      this.hoverLeave();
    });
  }

  checkAndSetImmersiveClass() {
    const nonImmersiveStyle = document.querySelector("input[name='menu-header-display-style']")?.classList.contains('menu-header__non-immersive');
    if (nonImmersiveStyle) {
      this.componentHost.classList.remove('header-menu-product-immersive');
    } else {
      this.componentHost.classList.add('header-menu-product-immersive');
    }
  }

  hoverEnter() {
    this.componentHost.classList.remove('header-menu-product-immersive');
  }
  hoverLeave() {
    if (!this.header?.classList.contains('ace-toggleNavbar--open')) {
      this.checkAndSetImmersiveClass();
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(HeaderMenuProduct.CLASS_NAMESPACE, HeaderMenuProduct);
