/* eslint-disable valid-jsdoc */
/**
 * @author Jonas.Fournel
 * @class ImmersiveHeading
 * @classdesc JS Class handling Immersive Heading component with HTML tag 'sofitel-immersive-heading'
 */


export default class ImmersiveHeading extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-immersive-heading';
  /** @inheritDoc */ constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }
  /** @inheritDoc */ initialize() {
    super.initialize();
    this.initImmersiveHeading();
  }
  /**
 * Initialize Immersive heading
 */ initImmersiveHeading() {
    this.isEditMode = this.componentHost.classList.contains('edit-mode');
    this.count = 1;
    this.activeElement = null;
    this.activeViewer = null;
    this.timeoutDelay = 4000;
    this.activeTimeout = null;
    this.activeTimeoutFunction = null;
    this.mediaType = this.componentHost.dataset.mediaType;
    this.videoElement = this.componentHost.querySelector('.content-background-media video');
    this.totalMedia = this.componentHost.dataset.mediaNumber;
    this.pagination = this.componentHost.querySelector('.controls-pagination');
    this.playPauseButton = this.componentHost.querySelector('button.play-pause');
    this.footerAnchor = this.componentHost.querySelector('div.footer-wrapper');
    this.narrowMediaActive = false;
    this.componentHost.querySelector('.content-wrapper')?.classList?.remove('hidden');
    this.contentWrapperMedia = this.componentHost.querySelector('.content-wrapper:not(.hidden)');

    if (this.totalMedia > 1) {
      this.pagination.innerText = `${this.count}/${this.totalMedia}`;
    } else {
      if (this.pagination) {
        this.pagination.style.display = 'none';
      }
    }

    if (!this.isEditMode) {
      if (this.mediaType === 'images') {
        this.activateElement(this.contentWrapperMedia);
        if (this.playPauseButton) {
          this.playPauseButton.classList.add('pause-display');
          this.playPauseButton.classList.remove('play-display');
          this.playPauseButton.querySelector('span').innerText = this.playPauseButton.dataset.playAriaLabel;
        }
        this.playPauseBehavior();
      } else {
        this.videoElement?.addEventListener('loadeddata', ()=>{
          this.activateElement(this.contentWrapperMedia);
          this.playPauseBehavior();
        });
      }
    }
  }
  /**
 * @param htmlElement
 */ activateElement(htmlElement) {
    if (htmlElement) {
      htmlElement.classList.remove('hidden');
      if (this.activeElement) this.deactivateElement();
      this.activeElement = htmlElement;
      if (this.totalMedia > 1 || this.mediaType === 'videos') {
        this.playPauseButton.disabled = null;
        this.activeTimeoutFunction = ()=>{
          this.count++;
          if (this.count > this.totalMedia) this.count = 1;
          this.pagination.innerText = `${this.count}/${this.totalMedia}`;
          if (this.narrowMediaActive) ;
          else this.activateElement(this.componentHost.querySelector(`.wrapper-${this.count}`));
        };
        this.activeTimeout = setTimeout(this.activeTimeoutFunction, this.timeoutDelay);
      }
    }
  }
  /**
 * @param htmlElement
 */ deactivateElement() {
    this.activeElement.classList.add('hidden');
  }
  /**
 * Toggle play pause button functionality on click
 */ playPauseBehavior() {
    if (this.mediaType === 'videos' || (this.mediaType === 'images' && this.totalMedia > 1)) {
      const isSlowDevice = document.body.classList.contains('slow-connection-device');
      if (isSlowDevice) {
        this.playPauseButton.classList.remove('pause-display');
        this.playPauseButton.classList.add('play-display');
        this.playPauseButton.querySelector('span').innerText = this.playPauseButton.dataset.pauseAriaLabel;
      }
      this.playPauseButton.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        if (this.playPauseButton.classList.contains('pause-display')) {
        // Pause logic
          if (this.mediaType === 'videos') {
            this.videoElement?.pause();
          } else {
            clearTimeout(this.activeTimeout);
          }
          this.playPauseButton.classList.remove('pause-display');
          this.playPauseButton.classList.add('play-display');
          this.playPauseButton.querySelector('span').innerText = this.playPauseButton.dataset.pauseAriaLabel;
        } else {
        // Play logic
          if (this.mediaType === 'videos') {
            this.videoElement?.play();
          } else {
          // Restart the carousel by reinitializing the timeout
            this.activeTimeout = setTimeout(this.activeTimeoutFunction.bind(this), this.timeoutDelay);
          }
          this.playPauseButton.classList.add('pause-display');
          this.playPauseButton.classList.remove('play-display');
          this.playPauseButton.querySelector('span').innerText = this.playPauseButton.dataset.playAriaLabel;
        }
      });
    }
  }
}
// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(ImmersiveHeading.CLASS_NAMESPACE, ImmersiveHeading);
