export { getToken, getTokenFromIframe, Error }

import {
  getJwt,
  getJwtPayload,
  getAuthStatus,
  setTokenCookie,
  setAuthCookie,
  AuthCookieValue,
  deleteAuthCookies,
  setDisconnectCookie,
  AuthenticationStatus,
} from './shared.js'
import { ALL_ACCOR_COM, CONNECTION_COOKIE_KEY } from '../common/constants.js'
import Cookies from 'js-cookie'
import { getAuthUrl } from './getAuthUrl.js'
import { refreshToken } from './refreshToken.js'
import { Enum, debouncePromiseGlobally } from '../common/utils.js'

const Error = Enum('GetTokenFailed', 'GetTokenEmpty')

const getToken = debouncePromiseGlobally(async (options, global = window) => {
  if (Cookies.get(CONNECTION_COOKIE_KEY) === AuthCookieValue.Failure) {
    setDisconnectCookie()
    throw Error.GetTokenFailed
  }

  switch (getAuthStatus()) {
    case AuthenticationStatus.Init:
      try {
        return await refreshToken(options)
      } catch (e) {
        deleteAuthCookies()
        throw e
      }

    case AuthenticationStatus.Failed:
      return getTokenFromIframe(global)

    case AuthenticationStatus.Success: {
      const token = getJwt()
      if (!token) throw Error.GetTokenEmpty
      return { token }
    }
  }
}, '_AccorGetToken')

const getTokenFromIframe = (global = window) =>
  new Promise((resolve, reject) => {
    const iframe = appendAutologIframe(global)
    const listener = ({ origin, data }) => {
      const cleanup = () => {
        cancelTimeout()
        global.removeEventListener('message', listener)
        iframe.remove()
      }
      if (origin === ALL_ACCOR_COM && data?.token) {
        const { token } = data
        setTokenCookie(token)
        const { pmid } = getJwtPayload(token)
        setAuthCookie(pmid)
        resolve({ token })
        cleanup()
      } else if (data === 'GetTokenError') {
        setDisconnectCookie()
        reject(Error.AutoLoginFailed)
        cleanup()
      }
    }

    const timeout = setTimeout(() => {
      console.warn('getToken : Rejecting after 4s of non response')
      reject(Error.AutoLoginFailed)
    }, 4000)

    const cancelTimeout = () => {
      clearTimeout(timeout)
    }

    global.addEventListener('message', listener)
  })

const appendAutologIframe = global => {
  const url = getAuthUrl({ state: 'autologin' })
  const iframe = global.document.createElement('iframe')
  iframe.id = 'autologin-iframe'
  iframe.style.display = 'none'
  iframe.src = url

  return global.document.body.appendChild(iframe)
}
