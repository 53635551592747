import { formatNewsletterUrl, getBrandFromDataLayer, validateEmail } from './newsletter.utils';
export default class Newsletter extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-newsletter';

  constructor(componentHost) {
    super(componentHost);
  }
  initialize() {
    super.initialize();

    this.lang = document.documentElement.lang.slice(0, 2) || 'en';
    this.brand = getBrandFromDataLayer() || 'novotel';
    this.apiUrl = formatNewsletterUrl({ lang: this.lang, brand: this.brand });

    this.newsLetterValid();
  }
  newsLetterValid() {
    const validButton = this.componentHost.querySelector(`.${Newsletter.CLASS_NAMESPACE}__button button`);
    const validInput = this.componentHost.querySelector(`.${Newsletter.CLASS_NAMESPACE}__input input`);
    const dataError = this.componentHost?.dataset?.error;
    const errorDiv = this.componentHost.querySelector(`.${Newsletter.CLASS_NAMESPACE}__email--error`);


    validButton?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      if (validateEmail(validInput.value)) {
        errorDiv.innerHTML = '';
        validInput.classList.remove('invaild');
        window.location = `${this.apiUrl}${validInput.value}`;
      } else {
        validInput.classList.add('invaild');
        errorDiv.innerHTML = dataError || '';
      }
    });
  }
}

CoreJS.BaseComponent.registerComponent(Newsletter.CLASS_NAMESPACE, Newsletter);
