import { addHeaderInterceptor } from './add-header-interceptor-factory'
import { getToken } from '../../oidc/getToken.js'
import { X_TRACEID, ORIGIN, X_ORIGIN } from '../../common/constants.js'

const makeGetTokenOptionsObj = config => ({
  origin: config[ORIGIN] || config[X_ORIGIN],
  ...(config.headers[X_TRACEID] && {
    headers: { [X_TRACEID]: config.headers[X_TRACEID] },
  }),
})

const getJWT = async config => {
  try {
    const { token } = await getToken(makeGetTokenOptionsObj(config))
    return token
  } catch (e) {
    if (config.getTokenErrorHandler) {
      return await config.getTokenErrorHandler(e)
    }
  }
}

const bearerTokenInterceptor = async axiosInstance => {
  axiosInstance.interceptors.request.use(async config =>
    addHeaderInterceptor(
      'Authorization',
      `Bearer ${await getJWT(config)}`,
      config
    )
  )
}

export default bearerTokenInterceptor
