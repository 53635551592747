/* eslint-disable arrow-parens */
/* eslint-disable camelcase */
export default class SocialMediaV2 extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'social-media';

  initialize() {
    // allow v1 and v2 coexistence (-> only v2 have data-provider)
    this.socialMediaWrapper = this.componentHost.querySelector('[data-provider]');
    if ( !this.socialMediaWrapper ) return;

    const { provider, teamId, tag, keyapi, albumId } = this.socialMediaWrapper.dataset;

    this.providerName = provider;
    this.teamID = teamId;
    this.apiKeyEmplifi = keyapi;
    this.albumID = albumId;
    this.tags = tag?.split(',').map(item => item.trim());

    this.url = null;
    this.constructedElements = [];
    this.postsCount = 8;

    this.setOptions();
    this.intersectionManager();
  }

  setOptions() {
    // this separated function will be usefull to update some options values
    // when the component will be extended elsewhere
    // ex: this.postsCount = 12;
  }

  intersectionManager() {
    const options = {
      rootMargin: '350px',
      threshold: 0
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          observer.unobserve(this.socialMediaWrapper);
          this.actionsDispatcher();
        }
      });
    }, options);

    observer.observe(this.socialMediaWrapper);
  }

  actionsDispatcher() {
    this.constructUrl();
    this.doFetchAndParse().then(() => {
      this.reshapeData();
      this.constructElements();
      this.appendElements();
    });
  }

  constructUrl() {
    switch (this.providerName) {
      case 'tint':
        this.url = `https://api.tintup.com/v2/tints/${this.teamID}/posts?`;
        this.url += `page[size]=${this.postsCount}`;
        this.url += '&filter[status]=public';

        if (this.tags?.length) {
          this.url += `&filter[tag]=${this.tags.join(',')}&include=product_tags.product,social_feed,cta_associations.cta,asset`;
        } else {
          this.url += '&sort=-published_at';
        }
        break;

      case 'emplifi':
        this.url = `https://distillery.pixlee.co/api/v2/albums/${this.albumID}/photos?`;
        this.url += `api_key=${this.apiKeyEmplifi}&sort={'recency':true,'desc':true}`;
        this.url += `&per_page=${this.postsCount}`;

        if (this.tags?.length) {
          const stringifiedTags = this.tags.map(tag => `'${tag}':true`).join(',').trim();
          this.url += `&filters={${stringifiedTags}}`;
        }
        break;
    }
  }

  doFetchAndParse() {
    return new Promise(async res => {
      const response = await fetch(this.url)
        .catch(() => {
          throw new Error('Fetch issue with this url : ', this.url);
        });

      await response.json()
        .then((parsedData) => {
          this.fetchedData = parsedData.data;
          res();
        })
        .catch((error) => {
          throw new Error('Json parsing issue : ', error);
        });
    });
  }

  reshapeData() {
    this.agnosticData = [];

    switch (this.providerName) {
      case 'tint':
        this.fetchedData.forEach((item, index) => {
          const { url, image_url, alternate_text, language, text } = item.attributes;
          this.agnosticData[index] = {
            url,
            imageUrl: image_url,
            alternateText: alternate_text,
            language,
            text
          };
        });
        break;

      case 'emplifi':
        this.fetchedData.forEach((item, index) => {
          const { platform_link, pixlee_cdn_photos, medium_url, alt_text, language, title } = item;
          this.agnosticData[index] = {
            url: platform_link,
            imageUrl: pixlee_cdn_photos.square_medium_url || medium_url,
            alternateText: alt_text,
            language,
            text: title
          };
        });
        break;
    }
  }

  constructElements() {
    this.agnosticData.forEach((item, index) => {
      const child = `
        <li class="aem-GridColumn aem-GridColumn--default--3 aem-GridColumn--sm--2 aem-GridColumn--md--4 cmp-socialMedia_listItem">
          <div class="cmp-socialMedia_listItemContainer">
            <a href=${item.url} title='Open publication on Instagram in a new window' class='cmp-socialMedia_listItemUrl' target="_blank">
              <picture class="cmp-socialMedia_listItemPicture">
                <img aria-describedby="social-media-${index}"  src="${item.imageUrl}" alt="${item.alternateText ? item.alternateText : '' }" loading="lazy" />
              </picture>
            </a>
            <div class="cmp-socialMedia_overlay">
              <p lang="${item.language}" id="social-media-${index}" class="cmp-socialMedia_overlayText">${item.text}</p>
            </div>
          </div>
        </li>
      `;

      this.constructedElements.push(child);
    });
  }

  appendElements() {
    const wrapper = this.componentHost.querySelector('#cmp-socialMedia_teaser--wrapper');

    this.constructedElements.forEach(element => {
      wrapper.innerHTML += element;
    });
  }
}

CoreJS.BaseComponent.registerComponent(SocialMediaV2.CLASS_NAMESPACE, SocialMediaV2);
