/* eslint-disable no-unused-expressions */
/**
 *
 * @author Samuvel Jayaprakash
 * @class DestinationDropdown
 * @version 1.0
 * @inheritDoc
 */
export default class DestinationDropdown extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-core-booking-engine__destination-dropdown';
  static hotelFilter = 'hotelFilter';
  static cityFilter = 'cityFilter';
  static countryFilter = 'countryFilter';
  static BEFORE_END = 'beforeend';

  /** @inheritDoc */
  constructor(componentHost) {
    super(componentHost);
  }

  /** @inheritDoc */
  initialize() {
    super.initialize();
    this.destinationDropdownHooks();
    this.destinationDropdownBindEvents();
  }

  // All the querySelector defined in destinationDropdownHooks function
  destinationDropdownHooks() {
    this.pageLang = document.documentElement?.lang;
    this.displayFilter = this.componentHost?.dataset.displayFilter;
    if (this.displayFilter === DestinationDropdown.countryFilter) {
      this.componentHost?.closest('.core-booking-engine')?.classList.add('two-destination-dropdown');
    }
    this.apidomainvalue = this.componentHost?.closest('.ace-core-booking-engine')?.querySelector('[name="aemApiDomain"]')?.value;
    this.offerPagePath = this.componentHost.querySelector(`.${DestinationDropdown.CLASS_NAMESPACE}-hidden`)?.dataset.pagePath;
    this.url = `${this.apidomainvalue}accorApi/geodata/hotels?lang=${this.pageLang}&offerPagePath=${this.offerPagePath}`;
    this.destinationDropdownSelect = this.componentHost.querySelector('.ace-destination-dropdown__select');
    this.destinationCountryDropdownSelect = this.componentHost.querySelector('.ace-destination-dropdown__select-country');
    this.destinationCityHotelDropdownSelect = this.componentHost.querySelector('.ace-destination-dropdown__select-ch');
    this.destinationDropdownHotelApiHandler();
  }

  // destinationDropdownHotelApiHandler function to handle API data & appending in Destination Dropdown
  destinationDropdownHotelApiHandler(changeCountry = '', evtCahnge = false) {
    this.finalUrl = evtCahnge && changeCountry ? `${this.url}&country=${changeCountry}` : `${this.url}`;

    const renderDestinationDropdown = (jsonData) => {
      const isEmptyJson = jsonData ? Object.keys(jsonData?.hotels).length === 0 : '';
      if (isEmptyJson !== '' && isEmptyJson !== true) {
        const uniqueJsonArray = [];
        let duplicateJsonArray = [];
        // Sorting json Asecending order value based on language
        if (this.displayFilter === DestinationDropdown.hotelFilter || (changeCountry && evtCahnge)) {
          jsonData.hotels
            .sort((a, b) => a.city === b.city ?
              a?.hotelName.localeCompare(b.hotelName) :
              a?.city.localeCompare(b.city));
          // Spliting two json unique & duplicate
          for (const data of jsonData.hotels) {
            if (!uniqueJsonArray.find((x) => x.city === data.city)) {
              uniqueJsonArray.push(data);
            } else {
              duplicateJsonArray.push(data);
            }
          }
        } else if (this.displayFilter === DestinationDropdown.cityFilter || (this.displayFilter === DestinationDropdown.countryFilter && !evtCahnge)) {
          jsonData.hotels
            .sort((a, b) => a.country === b.country ?
              a?.city.localeCompare(b.city) :
              a?.country.localeCompare(b.country));
          // Spliting two json unique & duplicate
          for (const data of jsonData.hotels) {
            if (!uniqueJsonArray.find((x) => x.country === data.country)) {
              uniqueJsonArray.push(data);
            } else {
              duplicateJsonArray.push(data);
            }
          }
          if (this.displayFilter === DestinationDropdown.cityFilter) {
            duplicateJsonArray = duplicateJsonArray.filter((obj, index) => {
              return index === duplicateJsonArray.findIndex((o) => obj.city === o.city);
            });
          }
        }

        // passing unique & duplicate json destinationDropdownHotelHTML function to create dropdown
        this.destinationDropdownHotelHTML(uniqueJsonArray, duplicateJsonArray, evtCahnge);
      } else {
        console.error('Empty json response in destination dropdown');
      }
    };
    fetch(`${this.finalUrl}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong');
      })
      .then((responseJson) => {
        // Do something with the response
        renderDestinationDropdown(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  destinationDropdownHotelHTML(uniqueJsonArray, duplicateJsonArray, evtCahnge) {
    let dynamicOptionMarkup = '';

    // createOption function to create option markup dynamically
    const createOption = (ucity, uhotelName, uhotelCode, ucountry, ucountryMaster, ucityMaster) => {
      let dynamicOption = '';
      if (this.displayFilter === DestinationDropdown.hotelFilter || evtCahnge) {
        const exists = duplicateJsonArray.filter((item) => item.city === ucity).length > 0;
        if (exists) {
          dynamicOption = `<option value="${uhotelCode}">${uhotelName}</option>`;
          for (const optionDuplicate of duplicateJsonArray) {
            if (optionDuplicate.city === ucity) {
              dynamicOption += `<option value="${optionDuplicate.hotelCode}">${optionDuplicate.hotelName}</option>`;
            }
          }
        } else {
          dynamicOption = `<option value="${uhotelCode}">${uhotelName}</option>`;
        }
      } else if (this.displayFilter === DestinationDropdown.cityFilter) {
        const exists = duplicateJsonArray.filter((item) => item.country === ucountry).length > 0;
        if (exists) {
          dynamicOption = `<option value="${ucityMaster}, ${ucountryMaster}">${ucity}</option>`;
          for (const optionDuplicate of duplicateJsonArray) {
            if (optionDuplicate.country === ucountry && optionDuplicate.city !== ucity) {
              dynamicOption += `<option value="${optionDuplicate.city_master}, ${optionDuplicate.country_master}">${optionDuplicate.city}</option>`;
            }
          }
        } else {
          dynamicOption = `<option value="${ucityMaster}, ${ucountryMaster}">${ucity}</option>`;
        }
      }
      return dynamicOption;
    };

    // loop to create optiongrp markup dynamically
    for (const optiongrp of uniqueJsonArray) {
      if (this.displayFilter === DestinationDropdown.hotelFilter || evtCahnge) {
        if (optiongrp.city) {
          dynamicOptionMarkup += `<optgroup label="${optiongrp.city}"> 
              ${createOption(optiongrp.city, optiongrp.hotelName, optiongrp.hotelCode, optiongrp.country, optiongrp.country_master, optiongrp.city_master)}
          </optgroup>`;
        }
      } else if (this.displayFilter === DestinationDropdown.cityFilter) {
        if (optiongrp.country) {
          dynamicOptionMarkup += `<optgroup label="${optiongrp.country}"> 
              ${createOption(optiongrp.city, optiongrp.hotelName, optiongrp.hotelCode, optiongrp.country, optiongrp.country_master, optiongrp.city_master)}
            </optgroup>`;
        }
      } else if (this.displayFilter === DestinationDropdown.countryFilter && !evtCahnge) {
        if (optiongrp.country) {
          dynamicOptionMarkup += `<option value="${optiongrp.country}"> 
              ${optiongrp.country}
            </option>`;
        }
      }
    }

    // removeOptionGrp function to removing optiongrp markup before injecting optiongrp markup
    const removeOptionGrp = (selector) => {
      const optgrp = selector?.querySelectorAll('optgroup');
      const options = selector?.querySelectorAll('option');
      optgrp?.forEach((element) => {
        element?.remove();
      });
      options?.forEach((option, index) => {
        if (index !== 0) {
          option?.remove();
        }
      });
    };

    // injecting optiongrp markup in select box
    if (dynamicOptionMarkup) {
      if (this.displayFilter === DestinationDropdown.countryFilter && !evtCahnge) {
        removeOptionGrp(this.destinationCountryDropdownSelect);
        this.destinationCountryDropdownSelect?.insertAdjacentHTML(DestinationDropdown.BEFORE_END, dynamicOptionMarkup);
      } else if (evtCahnge) {
        removeOptionGrp(this.destinationCityHotelDropdownSelect);
        this.destinationCityHotelDropdownSelect?.insertAdjacentHTML(DestinationDropdown.BEFORE_END, dynamicOptionMarkup);
        this.destinationCityHotelDropdownSelect?.removeAttribute('disabled');
        this.destinationCityHotelDropdownSelect?.focus();
      } else if (this.displayFilter === DestinationDropdown.hotelFilter || this.displayFilter === DestinationDropdown.cityFilter && !evtCahnge) {
        removeOptionGrp(this.destinationDropdownSelect);
        this.destinationDropdownSelect?.insertAdjacentHTML(DestinationDropdown.BEFORE_END, dynamicOptionMarkup);
      }
    }
  }

  // All the Events defined in destinationDropdownBindEvents function
  destinationDropdownBindEvents() {
    this.destinationCountryDropdownSelect?.addEventListener(CoreJS.DomEventConstants.CHANGE, (e) => {
      if (this.destinationCityHotelDropdownSelect) {
        this.destinationCityHotelDropdownSelect?.setAttribute('disabled', true);
        this.destinationCityHotelDropdownSelect.options[0].text = '';
        this.destinationCityHotelDropdownSelect.options[0].value = '';
      }
      if (e.target.value) {
        this.destinationDropdownHotelApiHandler(e.target.value, true);
      }
    });
    this.destinationCityHotelDropdownSelect?.addEventListener(CoreJS.DomEventConstants.CHANGE, (e) => {
      const selectedCity = e?.target?.options[e?.target?.selectedIndex];
      if (selectedCity) {
        e.target.options[0].text = `${selectedCity.text}, ${selectedCity.closest('optgroup')?.getAttribute('label')}`;
        e.target.options[0].value = e.target.value;
        e.target.options[0].selected = true;
      }
    });
  }
}

CoreJS.BaseComponent.registerComponent(
  DestinationDropdown.CLASS_NAMESPACE,
  DestinationDropdown,
);
