import { ACR_GRADE_LEVEL } from '../common/constants.js'
import { extractAcrFromToken } from '../common/utils.js'
import { getToken } from './getToken.js'

export const hasRequiredTokenACR = async acrLevel => {
  try {
    const { token } = await getToken()
    const acrToken = extractAcrFromToken(token)
    const requiredACR = ACR_GRADE_LEVEL[acrLevel]
    const claimACR = ACR_GRADE_LEVEL[acrToken]

    return claimACR >= requiredACR
  } catch (e) {
    console.error(e)

    return false
  }
}
