/* eslint-disable no-undef */
import TagManager from '@accor/core-services/dist/gtm/index';

export default class HeaderNavigation extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-header-navigation';
  static BREAK_POINTS = CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg;

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.headerNavigationHooks();
    this.dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    this.dataLayerjson = JSON.parse(this.dataLayer);
    this.isMercure = this.dataLayerjson.pageName.includes('mercure');
    this.header = this.componentHost.closest('header');
    this.backButton = this.componentHost.querySelectorAll('.ace-header-navigation .sub-nav-container__menu__back button');
    this.geolocBackBtn = this.componentHost.querySelector('.pos-container-geolocation__menu__back button');
    this.currencyBackBtn = this.componentHost.querySelector('.pos-container-currency__menu__back button');
    this.allAnchorMenu = this.componentHost?.querySelectorAll('a[href]:not(button)');
    this.menuList = this.menucontainer?.querySelectorAll('.link-navigation_nav_menu-list > li:not(.--hidden)');
    this.subMenuPosition = '';
    this.menuList?.forEach((list, index) => {
      list?.setAttribute('data-menu-position', index + 1);
    });
    // overall anchor tag tracking
    this.allAnchorMenu?.forEach((anchor) => {
      anchor.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
        const dataTracking = e.currentTarget?.dataset?.tracking;
        const position = e.currentTarget?.parentElement?.dataset?.menuPosition;
        this.activeBtn = this.componentHost.querySelector('.activeBtn');
        const btnTracking = this.activeBtn?.dataset?.tracking;
        let menuLable;
        const datasEvent = {
          event: 'GAevent',
          eventAction: 'Menu - Click on CTA',
          eventCategory: 'Navigation',
          eventLabel: `${dataTracking ? dataTracking : ''}`
        };
        // exclude "mercure" because of datalayer doublons : https://accor-it.atlassian.net/browse/ACE-405
        if (!this.isMercure) {
          TagManager.trackEvent(datasEvent);
        }
        if (dataTracking || btnTracking) {
          if (position) {
            this.subMenuPosition = position;
            menuLable = dataTracking?.toLowerCase();
          } else {
            if (this.activeBtn?.classList?.contains('link-navigation__more-btn')) {
              menuLable = `${dataTracking ? dataTracking.toLowerCase() : ''}`;
            } else {
              menuLable = `${btnTracking ? btnTracking.toLowerCase() : ''} - ${dataTracking ? dataTracking.toLowerCase() : ''}`;
            }
          }
        }
        const dataGA4Event = {
          event: 'GA4event',
          eventName: 'menu_interact',
          event_data: {
            pagename: `${this.dataLayerjson.pageName || ''}`,
            menu_position: `${this.subMenuPosition || ''}`,
            menu_label: `${menuLable || ''}`
          }
        };
        if (!this.isMercure) {
          window.dataLayer.push({
            'event_data': null
          });
          TagManager.trackEvent(dataGA4Event);
        }
      });
    });

    this.backButton?.forEach((button) => {
      button.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.closeSubMenuDropdownPanelHanlder();
      });
    });

    this.geolocBackBtn?.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.closeSubMenuDropdownPanelHanlder();
    });

    this.currencyBackBtn?.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.closeSubMenuDropdownPanelHanlder();
    });
  }

  headerNavigationHooks() {
    this.header = this.componentHost.closest('header');
    this.burgerMenubtn = this.componentHost.querySelector('.ace-navbar-toggler');
    this.navContent = this.componentHost.querySelector('.ace-header-navigation__main-container');
    this.geolocationSelector = this.componentHost.querySelector('.ace-geolocation-selector');
    this.overlay = this.componentHost.querySelector('.ace-header-navigation .ace-header-navigation__overlay');
    this.menuMainWrapper = this.componentHost?.querySelector(`.${HeaderNavigation.CLASS_NAMESPACE}__wrapper`);
    this.menuMainContainer = this.componentHost.querySelector(`.${HeaderNavigation.CLASS_NAMESPACE}__main-container`);
    this.navigationPanel = this.menuMainContainer?.querySelectorAll('.link-navigation__panel');
    this.navigationPanelWOGC = this.menuMainContainer?.querySelectorAll('.link-navigation__panel:not(.ace-geolocation-selector__modal, .ace-currency-selector__modal, .link-navigation__morePanel)');
    this.btnSubMenu = this.menuMainContainer?.querySelectorAll(
      'button.link-navigation__menu__link:not(.sub-nav-container__menu__back button, .pos-container-geolocation__menu__back button, .pos-container-currency__menu__back button)'
    );
    this.loginConnectBtn = this.componentHost.querySelector(`.${HeaderNavigation.CLASS_NAMESPACE}__loginconnect`);
    this.menucontainer = this.menuMainContainer?.querySelector(`.${HeaderNavigation.CLASS_NAMESPACE}__menu-container`);
    this.secondary = this.menucontainer?.querySelector('.link-navigation__sub-more-items');
    this.secondaryPanel = this.menucontainer?.querySelector('.link-navigation__sub-more-container');
    this.alertMessageBtn = document?.querySelector('.cmp-ace-message .cmp-ace-message__holder button');
    this.burgerMenuBtnHandler();
    this.subMenuBtnHandler();
    this.closeDropdownPanelOutsideClickHandler();
    this.escapeDropdownPanelKeyHandler();
    this.activateMoreBtnHeader();
    this.addMobileScrollHeight();
    this.checkImageNav();
    this.closeBurgerMenu();
    const { siteCode } = JSON.parse(
      document.body?.dataset?.posHandler
    );
    if (siteCode !== 'sofitel') {
      this.activateStickyHeader();
    }
  }

  activateStickyHeader() {
    const stickyAfter = 60;
    function scrollHeader() {
      if (window.scrollY > stickyAfter) {
        document.body.classList.add('enableSticky');
      } else {
        document.body.classList.remove('enableSticky');
      }
    }
    window.addEventListener(CoreJS.DomEventConstants.SCROLL, scrollHeader);
  }

  // close burger menu onclick of loginNav
  closeBurgerMenu() {
    this.loginConnectBtn?.addEventListener(CoreJS.DomEventConstants.CLICK, ()=>{
      if (this.navContent.classList.contains('show')) {
        this.burgerMenuHandler();
      }
    });
  }

  // Check if main-container has image Nav Inside
  checkImageNav() {
    this.allMainNav = this.menuMainContainer?.querySelectorAll('.sub-nav-container__main-nav');
    this.allMainNav?.forEach((item) => {
      this.hasImageNav = item.querySelector('.image-navigation');
      if (this.hasImageNav) {
        item.classList.add('hasImageNav');
      }
    });
  }

  /*
    More button functionality Start
    */
  activateMoreBtnHeader() {
    const primary = this.menucontainer?.querySelector('.link-navigation_nav_menu-list');
    const primaryItems = this.menucontainer?.querySelectorAll('.link-navigation_nav_menu-list > li:not(.link-navigation__moreitem)');
    // insert "more" button and duplicate the list
    let markupCount = true;
    const moreMarkup = () => {
      if (window.innerWidth >= HeaderNavigation.BREAK_POINTS) {
        if (markupCount) {
          if (this.secondary) {
            this.secondary.innerHTML = '';
          }
          this.secondaryPanel?.querySelectorAll('.link-navigation__panel')?.forEach((e) => e.remove());
          let linkSubItems = '';
          let panelItems = '';
          primaryItems?.forEach((item) => {
            this.linkNodes = item.querySelector('.link-navigation__menu__link')?.outerHTML;
            this.panelNodes = item.querySelector('.link-navigation__panel')?.outerHTML;
            if (this.linkNodes) {
              linkSubItems += this.linkNodes;
            }
            if (this.panelNodes) {
              panelItems += this.panelNodes;
            }
          });
          this.secondary?.insertAdjacentHTML('beforeend', `${linkSubItems}`);
          this.secondaryPanel?.insertAdjacentHTML('beforeend', `${panelItems}`);
          this.subMoreMenuBtnHandler();
          markupCount = false;
        }
      } else {
        if (this.secondary) {
          this.secondary.innerHTML = '';
        }
        this.secondaryPanel?.querySelectorAll('.link-navigation__panel')?.forEach((e) => e.remove());
        markupCount = true;
      }
    };
    moreMarkup();
    window.addEventListener(CoreJS.DomEventConstants.RESIZE, moreMarkup);
    // adapt tabs
    const doAdapt = () => {
      const secondaryItems = this.secondary?.querySelectorAll('.link-navigation__sub-more-items > .link-navigation__menu__link');
      const allItems = this.menucontainer?.querySelectorAll('li');
      const moreLi = primary?.querySelector('.link-navigation__moreitem');

      function clearHiddenItems() {
        // reveal all items for the calculation
        allItems?.forEach((item) => {
          item.classList?.remove('--hidden');
        });
        secondaryItems?.forEach((item) => {
          item.classList?.remove('--hidden');
        });
      }

      if (window.innerWidth >= HeaderNavigation.BREAK_POINTS) {
        clearHiddenItems();
        // hide items that won't fit in the Primary
        const hiddenItems = [];
        for (let i = primaryItems.length - 1; i >= 0; i--) {
          if (this.menuMainContainer?.querySelector(`.${HeaderNavigation.CLASS_NAMESPACE}__pos-container`)?.offsetTop > 0) {
            primaryItems[i].classList.add('--hidden');
            hiddenItems.push(i);
          } else {
            break;
          }
        }
        // toggle the visibility of More button and items in Secondary
        if (!hiddenItems.length) {
          moreLi?.classList?.add('--hidden');
        } else {
          secondaryItems?.forEach((item, i) => {
            if (!hiddenItems.includes(i)) {
              item.classList.add('--hidden');
            } else {
              item.classList.remove('--hidden');
            }
          });
        }
      } else {
        clearHiddenItems();
        moreLi?.classList?.add('--hidden');
      }
    };
    doAdapt(); // adapt immediately on load
    window.addEventListener(CoreJS.DomEventConstants.RESIZE, doAdapt); // adapt on window resize
    function activateDesktopHeader() {
      const mainDeskHeader = document?.querySelector(`.${HeaderNavigation.CLASS_NAMESPACE}__main-container`);
      if (!mainDeskHeader?.classList?.contains('--jsfied')) {
        mainDeskHeader?.classList?.add('--jsfied');
      }
    }

    activateDesktopHeader();
  }

  /*
    More button functionality End
    */

  subMoreMenuBtnHandler() {
    const secondaryBtn = this.secondary?.querySelectorAll('.link-navigation__sub-more-items > button.link-navigation__menu__link');
    secondaryBtn?.forEach((button, i) => {
      this.ariaSubControls = button.getAttribute('aria-controls');
      if (this.ariaSubControls) {
        button?.setAttribute('aria-controls', `more-${this.ariaSubControls + i}`);
      }
      button.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
        this.ariaControls = e.currentTarget?.getAttribute('aria-controls');
        if (e.currentTarget.classList.contains('activeMoreBtn') &&
                    this.secondaryPanel?.querySelector(`#${this.ariaControls}`)?.classList.contains('showMorePanel')) {
          this.closeSubMoreMenuDropdownPanelHanlder();
        } else {
          const dataTracking = e.currentTarget.dataset.tracking;
          const datasEvent = {
            event: 'GAevent',
            eventAction: 'Menu - Click on CTA',
            eventCategory: 'Navigation',
            eventLabel: `${dataTracking ? dataTracking : ''}`
          };
          const dataGA4Event = {
            event: 'GA4event',
            eventName: 'menu_interact',
            event_data: {
              pagename: `${this.dataLayerjson.pageName || ''}`,
              menu_label: `${dataTracking ? dataTracking.toLowerCase() : ''}`
            }
          };

          if (!this.isMercure) {
            TagManager.trackEvent(datasEvent);
            window.dataLayer.push({
              'event_data': null
            });
            TagManager.trackEvent(dataGA4Event);
          }
          this.openSubMoreMenuDropdownPanelHandler(e);
        }
      });
    });
    const secondaryPanel = this.secondaryPanel?.querySelectorAll('.link-navigation__panel');
    secondaryPanel?.forEach((panel, i) => {
      this.ariaSubPanelId = panel ? panel.id : '';
      if (this.ariaSubPanelId) {
        panel?.setAttribute('id', `more-${this.ariaSubPanelId + i}`);
        const panelContainer = panel?.querySelectorAll('.cmp-container');
        panelContainer?.forEach((container) => {
          if (container && container.id) {
            container?.removeAttribute('id');
          }
        });
      }
    });
  }

  openSubMoreMenuDropdownPanelHandler(e) {
    this.closeSubMoreMenuDropdownPanelHanlder();
    e.currentTarget.classList.add('activeMoreBtn');
    e.currentTarget.setAttribute('aria-expanded', true);
    this.ariaControls = e.currentTarget?.getAttribute('aria-controls');
    this.secondaryPanel?.querySelector(`#${this.ariaControls}`)?.classList.add('showMorePanel');
    this.secondaryPanel?.querySelector(`#${this.ariaControls}`)?.setAttribute('aria-hidden', false);
    this.trapFocus(this.secondaryPanel?.querySelector(`#${this.ariaControls}`), true, true);

    // fix open image on sub menu
    const checkImageLoaded = this.secondaryPanel?.querySelectorAll(`#${this.ariaControls} img[data-src-loaded=false]`);

    if (checkImageLoaded && typeof Modules != 'undefined' && typeof Modules.Image != 'undefined') {
      this.secondaryPanel?.querySelectorAll(`#${this.ariaControls} .ace-image`).forEach((picture) => {
        new Modules.Image.Image(picture);
      });
    }
  }

  closeSubMoreMenuDropdownPanelHanlder() {
    const secondaryPanel = this.secondaryPanel?.querySelectorAll('.link-navigation__panel');
    secondaryPanel?.forEach((panel) => {
      if (panel?.classList.contains('showMorePanel')) {
        this.getId = panel.id;
        panel.classList?.remove('showMorePanel');
        panel.setAttribute('aria-hidden', true);
        this.getActiveBtn = this.secondary.querySelector(`[aria-controls=${this.getId}]`);
        if (this.getActiveBtn?.classList.contains('activeMoreBtn')) {
          this.getActiveBtn?.classList.remove('activeMoreBtn');
          this.getActiveBtn?.setAttribute('aria-expanded', false);
          this.getActiveBtn?.focus();
        }
      }
    });
  }

  subMenuBtnHandler() {
    this.btnSubMenu?.forEach((button) => {
      button.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
        this.ariaControls = e.currentTarget?.getAttribute('aria-controls');
        if (e.currentTarget.classList.contains('activeBtn') &&
                    this.menuMainContainer?.querySelector(`#${this.ariaControls}`)?.classList.contains('showPanel')) {
          this.closeSubMenuDropdownPanelHanlder();
        } else {
          const dataTracking = e.currentTarget.dataset.tracking;
          const position = e.currentTarget.parentElement.dataset.menuPosition;
          this.subMenuPosition = position;
          let moreBtnHeader;
          const datasEvent = {
            event: 'GAevent',
            eventAction: 'Menu - Click on CTA',
            eventCategory: 'Navigation',
            eventLabel: `${dataTracking ? dataTracking : ''}`
          };
          if (e.currentTarget?.classList?.contains('link-navigation__more-btn')) {
            moreBtnHeader = 'more';
          } else {
            moreBtnHeader = `${dataTracking ? dataTracking.toLowerCase() : ''}`;
          }
          const dataGA4Event = {
            event: 'GA4event',
            eventName: 'menu_interact',
            event_data: {
              pagename: `${this.dataLayerjson.pageName || ''}`,
              menu_position: `${position || ''}`,
              menu_label: moreBtnHeader
            }
          };
          if (!this.isMercure) {
            TagManager.trackEvent(datasEvent);
            window.dataLayer.push({
              'event_data': null
            });
            TagManager.trackEvent(dataGA4Event);
          }
          this.openSubMenuDropdownPanelHandler(e);
        }
      });
    });
    this.loginConnectBtn?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.closeSubMenuDropdownPanelHanlder();
      this.loginConnectPanelCloseBtn = this.componentHost.querySelector(`.${HeaderNavigation.CLASS_NAMESPACE}__loginconnect .login-nav__item--close__button`);
      this.loginConnectPanelCloseBtn?.focus();
    });
  }

  openSubMenuDropdownPanelHandler(event) {
    this.closeSubMenuDropdownPanelHanlder();
    // disabling scroll from menu when subpanel opens in mobile view
    if (window.innerWidth < HeaderNavigation.BREAK_POINTS) {
      this.menuMainContainer.scrollTop = 0;
      this.menuMainContainer.style.overflowY = 'hidden';
    }
    event.currentTarget.classList.add('activeBtn');
    this.header?.classList.add('ace-toggleNavbar--open');
    event.currentTarget.setAttribute('aria-expanded', true);
    this.ariaControls = event.currentTarget?.getAttribute('aria-controls');
    this.menuMainContainer?.querySelector(`#${this.ariaControls}`)?.classList.add('showPanel');
    this.menuMainContainer?.querySelector(`#${this.ariaControls}`)?.setAttribute('aria-hidden', false);
    this.trapFocus(this.menuMainContainer?.querySelector(`#${this.ariaControls}`), true, false);
  }

  closeSubMenuDropdownPanelHanlder() {
    this.closeSubMoreMenuDropdownPanelHanlder();
    this.navigationPanel?.forEach((panel) => {
      if (panel?.classList.contains('showPanel')) {
        this.getId = panel.id;
        panel.classList?.remove('showPanel');
        panel.setAttribute('aria-hidden', true);
        // enabling scroll in mobile menu when subpanel is closed
        if (window.innerWidth < HeaderNavigation.BREAK_POINTS) {
          this.menuMainContainer.style.overflowY = 'auto';
          this.menuMainContainer.style.overflowX = 'hidden';
        }
        this.getActiveBtn = this.menuMainContainer?.querySelector(`[aria-controls=${this.getId}]`);
        if (this.getActiveBtn?.classList.contains('activeBtn')) {
          this.getActiveBtn?.classList.remove('activeBtn');
          if (window.innerWidth >= HeaderNavigation.BREAK_POINTS) {
            this.header?.classList.remove('ace-toggleNavbar--open');
          }
          this.getActiveBtn?.setAttribute('aria-expanded', false);
          this.getActiveBtn?.focus();
        }
      }
    });
  }

  trapFocus(element, showCheck = false, showMorecheck = false) {
    const setTimer = 100;
    const mobileBackFocusable = window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md ? '' : ', .link-navigation__menu__back';
    const focusableEls = element?.querySelectorAll(
      `a[href]:not([disabled], .--hidden, .--hidden a[href], .link-navigation__morePanel .link-navigation__panel a[href]),
      button:not([disabled]${mobileBackFocusable}, .--hidden, .--hidden button, .link-navigation__morePanel .link-navigation__panel button),
      .showMorePanel a[href]:not([disabled], .--hidden),
      .showMorePanel button:not([disabled]${mobileBackFocusable}, .--hidden),
      select:not([disabled])`
    );
    const mainFocusableEl = this.componentHost?.querySelectorAll(
      `a[href]:not([disabled], .ace-header-navigation__logo a[href], .--hidden, .--hidden a[href], .link-navigation__panel a[href]),
      button:not([disabled], .ace-navbar-toggler, .ace-header-navigation__logo button, .--hidden, .--hidden button, .link-navigation__panel button)`
    );
    const moreFocusableEl = this.componentHost?.querySelectorAll(
      `.link-navigation__morePanel a[href]:not([disabled], .--hidden, .--hidden a[href], .link-navigation__morePanel .link-navigation__panel a[href]),
      .link-navigation__morePanel button:not([disabled], .link-navigation__menu__back, .--hidden, .--hidden button, .link-navigation__morePanel .link-navigation__panel button)`
    );
    const firstFocusableEl = focusableEls && focusableEls[0] ? focusableEls[0] : null;
    const lastFocusableEl = focusableEls && focusableEls[focusableEls.length - 1] ? focusableEls[focusableEls.length - 1] : null;
    const { siteCode } = JSON.parse(
      document.body?.dataset?.posHandler
    );
    if (showCheck) {
      if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
        if (siteCode !== 'sofitel' && siteCode !== 'all') {
          focusableEls[1]?.focus();
        }
      } else {
        setTimeout(() => {
          firstFocusableEl?.focus();
        }, setTimer);
      }
    }
    const closeShowPanel = () => {
      const panel = element;
      if (panel?.classList?.contains('showPanel')) {
        const getId = panel.id;
        panel.classList?.remove('showPanel');
        panel.setAttribute('aria-hidden', true);
        const getActiveBtn = document?.querySelector(`[aria-controls=${getId}]`);
        if (getActiveBtn?.classList.contains('activeBtn')) {
          getActiveBtn?.classList.remove('activeBtn');
          this.header?.classList.remove('ace-toggleNavbar--open');
          getActiveBtn?.setAttribute('aria-expanded', false);
          return getActiveBtn;
        }
      }
    };
    const closeShowMorePanel = () => {
      const panel = element;
      if (panel?.classList?.contains('showMorePanel')) {
        const getId = panel.id;
        panel.classList?.remove('showMorePanel');
        panel.setAttribute('aria-hidden', true);
        const getActiveBtn = document?.querySelector(`[aria-controls=${getId}]`);
        if (getActiveBtn?.classList.contains('activeMoreBtn')) {
          getActiveBtn?.classList.remove('activeMoreBtn');
          getActiveBtn?.setAttribute('aria-expanded', false);
          return getActiveBtn;
        }
      }
    };
    element?.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (e) => {
      const isTabPressed = (e.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab || e.keyCode === CoreJS.Constants.KEY_CODES.tab);
      if (!isTabPressed) {
        return;
      }
      if (e.shiftKey) /* shift + tab */ {
        if (document.activeElement === firstFocusableEl) {
          if (showMorecheck) {
            const currentEle = closeShowMorePanel();
            currentEle?.focus();
          } else {
            const currentEle = closeShowPanel();
            currentEle?.focus();
          }
          e.stopPropagation();
          e.preventDefault();
        }
      } else {
        if (document?.activeElement === lastFocusableEl) {
          if (showMorecheck) {
            const currentEle = closeShowMorePanel();
            const focusIndex = Array.from(moreFocusableEl).indexOf(currentEle);
            if (focusIndex !== moreFocusableEl.length - 1) {
              moreFocusableEl[focusIndex + 1]?.focus();
              e.stopPropagation();
            } else {
              currentEle?.focus();
            }
          } else {
            const currentEle = closeShowPanel();
            const focusIndex = Array.from(mainFocusableEl).indexOf(currentEle);
            if (focusIndex === mainFocusableEl.length - 2 && window.innerWidth < HeaderNavigation.BREAK_POINTS) {
              this.burgerMenubtn?.focus();
            } else {
              mainFocusableEl[focusIndex + 1]?.focus();
            }
          }
          if (window.innerWidth < HeaderNavigation.BREAK_POINTS) {
            e.stopPropagation();
          }
          e.preventDefault();
        }
      }
    });
    if (element?.classList?.contains('link-navigation__morePanel') &&
            showMorecheck !== true) {
      function navigate(origin, sens) {
        let index = Array.from(focusableEls).indexOf(origin);
        index += sens;
        if (index < 0) {
          index = focusableEls.length - 1;
        }
        if (index > focusableEls.length - 1) {
          index = 0;
        }
        focusableEls[index]?.focus();
      }

      element?.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (e) => {
        if (e.shiftKey) {
          if (e.keyCode === CoreJS.Constants.KEY_CODES.left || e.keyCode === CoreJS.Constants.KEY_CODES.up) {
            firstFocusableEl?.focus();
          } else if (e.keyCode === CoreJS.Constants.KEY_CODES.right || e.keyCode === CoreJS.Constants.KEY_CODES.down) {
            lastFocusableEl?.focus();
          }
        } else {
          if (e.keyCode === CoreJS.Constants.KEY_CODES.left || e.keyCode === CoreJS.Constants.KEY_CODES.up) {
            navigate(e.target, -1);
          } else if (e.keyCode === CoreJS.Constants.KEY_CODES.right || e.keyCode === CoreJS.Constants.KEY_CODES.down) {
            navigate(e.target, 1);
          }
        }
      });
    }
  }

  trapMobileFocus(e, burgerMenu = false) {
    e.preventDefault();
    if (burgerMenu) {
      const focusableEls = this.menuMainWrapper?.querySelectorAll(
        `a[href]:not([disabled], .--hidden, .--hidden a[href], .link-navigation__panel a[href]),
        button:not([disabled], .--hidden, .--hidden button, .link-navigation__panel button)`
      );
      focusableEls[1]?.focus();
      const firstFocusableEl = focusableEls[0];
      const lastFocusableEl = focusableEls[focusableEls.length - 1];
      this.menuMainWrapper?.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (e) => {
        const isTabPressed = (e.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab || e.keyCode === CoreJS.Constants.KEY_CODES.tab);
        if (!isTabPressed) {
          return;
        }
        if (e.shiftKey) /* shift + tab */ {
          if (document.activeElement === firstFocusableEl) {
            lastFocusableEl?.focus();
            if (!this.componentHost?.querySelector('.ace-navbar-toggler[aria-expanded="false"]')) {
              e.preventDefault();
            }
          }
        } else /* tab */ {
          if (document.activeElement === lastFocusableEl) {
            firstFocusableEl?.focus();
            e.preventDefault();
          }
        }
      });
    }
  }

  /**
     * Close Custom On Clicking Outside
     */
  closeDropdownPanelOutsideClickHandler() {
    window.addEventListener(
      CoreJS.DomEventConstants.CLICK,
      (event) => {
        if (
          !event.target.closest(`.${HeaderNavigation.CLASS_NAMESPACE}`)
        ) {
          this.closeSubMenuDropdownPanelHanlder();
        }
      },
      false
    );
  }

  /**
     * Escape key handler
     */
  escapeDropdownPanelKeyHandler() {
    window.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
      if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.esc) {
        this.closeSubMenuDropdownPanelHanlder();
        if (window.innerWidth < (HeaderNavigation.BREAK_POINTS - 1)) {
          this.burgerMenuHandler();
          this.trapMobileFocus(event, true);
        }
      }
    });
  }

  removeExpericeFragDiv() {
    this.navigationPanelWOGC?.forEach((ele) => {
      const newHTML = ele?.querySelector('.sub-nav-container')?.outerHTML;
      ele.innerHTML = '';
      ele.innerHTML = newHTML ? newHTML : '';
    });
  }

  burgerMenuBtnHandler() {
    const { siteCode } = JSON.parse(
      document.body?.dataset?.posHandler
    );
    if (siteCode == 'sofitel') {
      this.burgerMenubtn?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this.burgerMenuHandler();
      });
    } else {
      this.burgerMenubtn?.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
        this.burgerMenuHandler();
        this.trapMobileFocus(e, true);
      });
    }
  }

  burgerMenuHandler() {
    if (this.burgerMenubtn) {
      const datasEvent = {
        event: 'GAevent',
        eventAction: 'Menu - Click on CTA',
        eventCategory: 'Navigation',
        eventLabel: 'Open menu'
      };
      const datasEvents = {
        event: 'GAevent',
        eventAction: 'Menu - Click on CTA',
        eventCategory: 'Navigation',
        eventLabel: 'Close menu'
      };
      const dataGA4Event = {
        event: 'GA4event',
        eventName: `${this.burgerMenubtn.getAttribute('aria-expanded') === 'false' ? 'menu_open' : 'menu_close'}`,
        event_data: {
          pagename: `${this.dataLayerjson.pageName || ''}`
        }
      };
      if (this.burgerMenubtn.getAttribute('aria-expanded') === 'false') {
        this.burgerMenubtn.setAttribute('aria-expanded', 'true');
        this.closeSubMenuDropdownPanelHanlder();
        this.overlay.classList.add('enableOverlay');
        document.body.classList.add('disableScroll');
        TagManager.trackEvent(datasEvent);
        window.dataLayer.push({
          'event_data': null
        });
        TagManager.trackEvent(dataGA4Event);
      } else {
        this.burgerMenubtn.setAttribute('aria-expanded', 'false');
        document.body.classList.remove('disableScroll');
        this.overlay.classList.remove('enableOverlay');
        TagManager.trackEvent(datasEvents);
        window.dataLayer.push({
          'event_data': null
        });
        TagManager.trackEvent(dataGA4Event);
        const { siteCode } = JSON.parse(
          document.body?.dataset?.posHandler
        );
        if (siteCode !== 'sofitel') {
          this.burgerMenubtn.focus();
        }
      }
      this.toggleModal();
    }
  }

  toggleModal() {
    this.navContent.classList.toggle('show');
    this.geolocationSelector.classList.toggle('show');
    this.header.classList.toggle('ace-toggleNavbar--open');
  }
  // Add height mobile scroll issue based window height

  addMobileScrollHeight() {
    const doAdaptHeight = () => {
      const headHeight = this.componentHost.closest('header')?.offsetHeight;
      const alertHeight = document.querySelector('.alert-banner .cmp-ace-message ') ? document.querySelector('.alert-banner .cmp-ace-message ').offsetHeight : 0;
      const headerHeight = headHeight + alertHeight;
      if (window.innerWidth < HeaderNavigation.BREAK_POINTS) {
        const mobileMainHeight = window.innerHeight - headerHeight;
        this.menuMainContainer.style.height = `${mobileMainHeight }px`;
      } else {
        this.menuMainContainer.style.height = 'auto';
      }
    };
    doAdaptHeight(); // adapt immediately on load
    window.addEventListener(CoreJS.DomEventConstants.RESIZE, doAdaptHeight); // adapt on window resize
    this.alertMessageBtn?.addEventListener(CoreJS.DomEventConstants.CLICK, doAdaptHeight); // re-calculate ht when alert is closed
  }
}
// Register the NavBar component with the BaseComponent
CoreJS.BaseComponent.registerComponent(HeaderNavigation.CLASS_NAMESPACE, HeaderNavigation);