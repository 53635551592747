import { BasePageTemplate } from '@accor/ace-ui-core';

export default class HeadingOffer extends BasePageTemplate {
  static CLASS_NAMESPACE = 'heading-offer-page-template';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.pdpCityTemplate = this.componentHost.classList.contains('pdp-city-template');

    if (this.pdpCityTemplate) {
      this.bookingEnginePDPCity();
    }
  }

  bookingEnginePDPCity() {
    window.addEventListener('load', () => {
      const duetDateElement = this.componentHost.querySelector('.duet-date');
      const duetDateDialogElement = this.componentHost.querySelector('.duet-date__dialog');
      const duetDateInputElement = this.componentHost.querySelector('.duet-date__input');
      const duetDateCloseELement = this.componentHost.querySelector('.duet-date__close');
      const duetDateContentElement = this.componentHost.querySelector('.duet-date__table');

      duetDateElement?.classList.add('is-active');
      duetDateDialogElement?.classList.add('is-active');
      duetDateInputElement?.classList.add('active');

      document?.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          duetDateElement?.classList.remove('is-active');
          duetDateDialogElement?.classList.remove('is-active');
          duetDateInputElement?.classList.remove('active');
        }
      });

      duetDateContentElement?.addEventListener('click', () => {
        duetDateElement?.classList.remove('is-active');
        duetDateDialogElement?.classList.remove('is-active');
        duetDateInputElement?.classList.remove('active');
      });

      duetDateCloseELement?.addEventListener('click', () => {
        duetDateElement?.classList.remove('is-active');
        duetDateDialogElement?.classList.remove('is-active');
        duetDateInputElement?.classList.remove('active');
      });
    });
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(BasePageTemplate.CLASS_NAMESPACE, HeadingOffer, true);
